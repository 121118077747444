<template>
  <v-card
    color="transparent"
    class="elevation-0">
    <v-card-text
      v-if="attributes.length"
      ref="attribute-input"
      class="pa-0">
      <CyResourceCustomAttributeInput
        v-for="(attribute, index) in attributes"
        :key="`attribute-${index}`"
        :pair="attributes[index]"
        @remove="onRemove(index)"/>
    </v-card-text>

    <v-card-actions class="pa-0">
      <CyButton
        ref="add-btn"
        icon="add"
        theme="primary"
        variant="secondary"
        @click="onAdd">
        <span>{{ $t('addAttribute') }}</span>
      </CyButton>
    </v-card-actions>
  </v-card>
</template>

<script>
import CyResourceCustomAttributeInput from '@/components/resource/custom-attribute-input'

export default {
  name: 'CyResourceCustomAttributes',
  components: {
    CyResourceCustomAttributeInput,
  },
  props: {
    attributes: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onAdd () {
      this.$emit('update:attributes', [
        ...this.attributes, {
          key: null,
          value: null,
        },
      ])
    },
    onRemove (index) {
      this.$emit('update:attributes', this.attributes.filter((_, i) => (i !== index)))
    },
  },
  i18n: {
    messages: {
      en: {
        addAttribute: 'Add attribute',
      },
      es: {
        addAttribute: 'Añadir atributo',
      },
      fr: {
        addAttribute: `Ajouter un attribut`,
      },
    },
  },
}
</script>
